.footer-section
{
  margin-top: 200px;
  display: flex;
  flex-direction: column;
}

.email
{
  margin-top: 20px;
  text-align: center;
}

.email a
{
  color: rgb(0, 0, 0);
  font-family: "Chicago";
  font-size: 13px;
  text-shadow: 1px 1px rgb(175, 175, 175);
  font-style: italic;
  transition: 0.2s all ease;
}

.email a:hover
{
  background: rgb(212, 212, 212);
}

.footer p
{
  margin-top: 10px;
  color: rgb(0, 0, 0);
  text-align: center;
  font-family: "Chicago";
  font-size: 12px;
}