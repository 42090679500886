.about-section
{
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}

.about-header
{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.about-header h1
{
  color: rgb(21, 77, 231);
  font-style: italic;
  font-family: "Chicago";
  text-shadow: 2px 2px rgb(175, 175, 175);
  font-size: 32px;
}

.description
{
  color: rgb(0, 0, 0);
  width: 70%;
  font-family: "Chicago";
}

.description p
{
  font-size: 19px;
  text-align: left;
  line-height: 1.4em;
}

.description a
{
  color: rgb(0, 0, 0);
  font-style: italic;
  text-decoration: none;
  font-family: "Chicago";
  text-shadow: 2px 2px rgb(221, 221, 221);
  transition: 0.2s all ease;
}

.description a:hover
{
  background: rgb(212, 212, 212);
}

.cat-highlight
{
  color:rgb(21, 77, 231);
}

.profile-photo
{
  margin-top: 87px;
}

.profile-photo img
{
  max-width: 180px;
  max-height: 180px;
}

@media only screen and (max-width: 700px)
{
  .about-header
  {
    padding-top: 3vh;
    padding-bottom: 3vh;
  }

  .description
  {
    width: 80%;
  }

  .description p
  {
    font-size: 4vw;
  }

  .profile-photo img
  {
    max-width: 125px;
    max-height: 125px;
  }
}

@media only screen and (min-width: 1400px)
{
  .about-header
  {
    background-size: contain;
    background-repeat: repeat-x;
  }
}