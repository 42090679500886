body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 18px;
}

::-webkit-scrollbar-track {
  background: rgb(170, 170, 170);
  display: block;
  border-color: rgb(0, 0, 0);
  border-width: 1px;
  border-style: inset;
}

::-webkit-scrollbar-thumb {
  background: rgb(219, 219, 219);
  display: block;
  border-color: rgb(0, 0, 0);
  border-width: 1px;
  border-style: outset;
}

/* SCROLL BUTTONS */
/* ::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
    display: block;
    border-color: rgb(0, 0, 0);
    border-width: 2px;
    border-style: outset;
    height: 17px;
    background-color: rgb(219, 219, 219);
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 900px) {
  body {
    display: block;
  }
}

.main-container
{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

/*GENERAL BACKGROUND*/
body
{
  background: rgba(70, 104, 196, 0.752);
  overflow-y:hidden;
}

/* FONTS */
@font-face
{
  font-family: "Avenir Book";
  src: url(/static/media/AvenirLTStd-Book.ecb0c2ae.otf);
}

@font-face
{
  font-family: "Avenir Roman";
  src: url(/static/media/AvenirLTStd-Roman.b1d7c6e0.otf);
}

@font-face
{
  font-family: "Avenir Medium";
  src: url(/static/media/AvenirLTStd-Medium.4f995fa4.otf);
}

@font-face
{
  font-family: "8 bit";
  src: url(/static/media/8-bit-pusab.0288f1e7.ttf);
}

@font-face
{
  font-family: "Chicago";
  src: url(/static/media/ChicagoFLF.d7982e4c.ttf);
}
.bottom-container
{
  flex-direction: column;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: rgb(210, 210, 210);
}

.bottom-menu-container
{
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.bottom-menu-container ul
{
  padding: 0;
  list-style-type: none;
}​

.bottom-menu
{
  border-style: outset;
}

.bottom-menu-item i
{
  color: rgb(0, 0, 0);
  padding: 8px 8px;
  background: rgb(219, 219, 219);
  border-color: rgb(139, 139, 139);
  border-width: 4px;
  border-style: outset;
  -webkit-transition: 0.2s all ease;
  transition: 0.2s all ease;
}

.bottom-menu-item i:hover
{
  background-color: rgb(152, 152, 152);
}

@media only screen and (max-height: 1000px)
{
  .bottom-menu-item i
  {
    color: rgb(0, 0, 0);
    padding: 4px 4px;
    background: rgb(219, 219, 219);
    border-color: rgb(169, 169, 169);
    border-width: 4px;
    border-style: outset;
    -webkit-transition: 0.2s all ease;
    transition: 0.2s all ease;
  }
}
.profile-page
{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-position: center;
}

.rectangle
{
  background: -webkit-linear-gradient(rgba(0,0,0,0.95), rgba(0,0,0,0.95)), no-repeat center;
  background: linear-gradient(rgba(0,0,0,0.95), rgba(0,0,0,0.95)), no-repeat center;
}

.profile-name h1
{
  margin-top: 40px;
  margin-left: 5px;
  font-size: 47px;
  font-style: oblique;
  color: rgb(0, 0, 0);
  text-shadow: 3px 3px rgb(198, 198, 198);
  font-weight: bold;
  font-family: "Chicago";
}

.profile-description h1
{
  margin-top: 20px;
  margin-bottom: 0px;
  width: 100%;
  font-family: "Chicago";
  color:rgb(0, 0, 0);
  font-size: 22px;
  text-overflow: ellipsis;
}

#cursor {
  border-left: .8em solid rgba(110, 110, 110, 0.676);
  padding-top: 7px;
}

@-webkit-keyframes blink {
  50% {
    border-color: transparent;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@media only screen and (max-width: 600px)
{
  .profile-name h1
  {
    font-size: 27px;
    text-shadow: 2px 2px rgb(164, 164, 164);
  }

  .profile-description h1
  {
    font-size: 15px;
  }
}


.main-nav
{
  display: flex;
  flex-direction: column;
}

.main-nav ul
{
  padding: 0;
  margin-top: 0px;
}

.nav-menu 
{
  list-style-type: none;
  display: flex;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  flex-direction: column;
  justify-content: center;
}

.home-nav-menu .nav-item
{
  color: rgb(0, 0, 0);
  font-family: "Chicago";
  text-shadow: 1px 1px rgb(193, 193, 193);
  text-decoration: underline;
  font-style: italic;
  font-size: 22px;
  margin-top: 40px;
  margin-left: 8px;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: 0.2s all ease;
  transition: 0.2s all ease;
}

.home-nav-menu .nav-item:hover
{
  background-color: rgb(212, 212, 212);
}

@media only screen and (max-width: 600px)
{
  .home-nav-menu .nav-item
  {
    font-size: 18px;
    margin-top: 30px;
    text-shadow: 1px 1px rgb(193, 193, 193);
  }
}
.topbar-container
{
  flex-direction: column;
  display: flex;
  margin-top: 0px;
  width: 100%;
  height: 29px;
  border-radius: 5px;
  border-color: rgb(96, 96, 96);
  border-style: outset;
  border-width: 2px;
  background: rgb(219, 219, 219);
}

.topbar-menu-container
{
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 0;
  list-style-type: none;
}

.topbar-menu-container li a
{
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-family: "Chicago";
  font-size: 19px;
  margin: 13px;
  overflow: hidden;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.topbar-menu-container li a:hover
{
  color: rgb(40, 40, 40);
}

.topbar-menu
{
  list-style-type: none;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 500px)
{
  .topbar-menu-container li a
  {
    font-size: 17px;
    margin: 9px;
  }
}
.about-section
{
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}

.about-header
{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.about-header h1
{
  color: rgb(21, 77, 231);
  font-style: italic;
  font-family: "Chicago";
  text-shadow: 2px 2px rgb(175, 175, 175);
  font-size: 32px;
}

.description
{
  color: rgb(0, 0, 0);
  width: 70%;
  font-family: "Chicago";
}

.description p
{
  font-size: 19px;
  text-align: left;
  line-height: 1.4em;
}

.description a
{
  color: rgb(0, 0, 0);
  font-style: italic;
  text-decoration: none;
  font-family: "Chicago";
  text-shadow: 2px 2px rgb(221, 221, 221);
  -webkit-transition: 0.2s all ease;
  transition: 0.2s all ease;
}

.description a:hover
{
  background: rgb(212, 212, 212);
}

.cat-highlight
{
  color:rgb(21, 77, 231);
}

.profile-photo
{
  margin-top: 87px;
}

.profile-photo img
{
  max-width: 180px;
  max-height: 180px;
}

@media only screen and (max-width: 700px)
{
  .about-header
  {
    padding-top: 3vh;
    padding-bottom: 3vh;
  }

  .description
  {
    width: 80%;
  }

  .description p
  {
    font-size: 4vw;
  }

  .profile-photo img
  {
    max-width: 125px;
    max-height: 125px;
  }
}

@media only screen and (min-width: 1400px)
{
  .about-header
  {
    background-size: contain;
    background-repeat: repeat-x;
  }
}
.projects-header h1
{
    color: rgb(0, 0, 0);
    font-family: "Chicago";
    font-weight: bold;
    text-shadow: 2px 2px rgb(180, 180, 180);
    font-style: oblique;
    font-size: 30px;
    margin-left: 10px;
}

.projects-section
{
    margin-bottom: 60px;
}

.projects-section ul
{
    padding: 0;
}

.project
{
    margin-bottom: 60px;
    margin-top: 90px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.project-photo img
{
    max-height: 165px;
    max-width:  165px;
}

.project-photo2 img
{
    height: 165px;
    width:  165px;
}

.projects-list h2
{
    font-family: "Chicago";
    font-size: 28px;
    color: rgb(21, 77, 231);
    text-shadow: 2px 2px rgb(175, 175, 175);
    text-align: center;
    margin: 8px;
}

.projects-list h3
{
    color: rgb(98, 98, 98);
    font-size: 17px;
    text-align: center;
    font-style: oblique;
    font-family: "Chicago";
}

.skills
{
    margin-top: 0px;
    display: flex;
    font-family: "Chicago";
    flex-direction: row;
    justify-content: center;
}

.skills li
{
    color: rgb(98, 98, 98);
    font-size: 14px;
    text-decoration: none;
    border: 1px solid rgb(98, 98, 98);
    border-radius: 3px;
    padding: 3px 10px;
    padding-bottom: 3px;
    margin: 3px;
    display: flex;
}

.project-description
{
    margin-top: 23px;
    color: rgb(0, 0, 0);
    width: 50%;
    font-family: "Chicago";
    align-items: center;
    justify-content: center;
}

.project-description p
{
    font-size: 18px;
    text-align: left;
    line-height: 1.3em;
    margin-bottom: 6px;
}

.github-link
{
    margin-top: 2px;
}

.github-link a
{
    color: rgb(71, 71, 71);
    font-family: "Chicago";
    text-decoration: underline;
    font-style: oblique;
    font-size: 20px;
    -webkit-transition: 0.2s all ease;
    transition: 0.2s all ease;
}

.github-link a:hover
{
    background: rgb(212, 212, 212);
}

.demo-link
{
    margin-top: 2px;
}

.demo-link a
{
    color: rgb(71, 71, 71);
    font-family: "Chicago";
    text-decoration: underline;
    font-style: oblique;
    font-size: 20px;
    -webkit-transition: 0.2s all ease;
    transition: 0.2s all ease;
}

.demo-link a:hover
{
    background: rgb(212, 212, 212);
}

@media only screen and (max-width: 700px)
{
    .project-description
    {
        width: 80%;
    }

    .project-description p
    {
        font-size: 4vw;
    }
}

@media only screen and (max-width: 500px)
{
    .skills
    {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .project
    {
        margin-top: 70px;
    }
}

@media only screen and (max-height: 1000px)
{
    .skills li
    {
        color: rgb(98, 98, 98);
        font-size: 12px;
        text-decoration: none;
        border: 1px solid rgb(98, 98, 98);
        border-radius: 3px;
        padding: 1px 6px;
        padding-bottom: 1px;
        margin: 2px;
    }

    .project-photo img
    {
        max-height: 130px;
        max-width:  130px;
    }

    .project-photo2 img
    {
        height: 130px;
        width:  130px;
    }

    .project
    {
        margin-bottom: 60px;
        margin-top: 50px;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
}
.photos-header h1
{
  color: rgb(0, 0, 0);
  padding-top: 10px;
  margin-left: 10px;
  text-shadow: 2px 2px rgb(180, 180, 180);
  font-style: oblique;
  font-family: "Chicago";
  font-weight: bold;
  font-size: 30px;
}

.photos img
{
  max-width: 100%;
  height: auto;
}

.photos
{
  display: flex;
  flex-wrap: wrap;
  margin: 0px 150px;
}

.column 
{
  flex: 25% 1;
  max-width: 30%;
  margin-left: auto;
  margin-right: auto;
}

.photo-box
{
  position: relative;
  padding-top: 0;
  margin-top: 10px;
}

.photo-box:hover img
{
  opacity: 0.3;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.photo-box:hover .detail
{
  opacity: 0;
}

.column img {
  vertical-align: middle;
}

.photos-link
{
  margin-top: 78px;
  font-family: "Chicago";
  font-size: 14px;
  color: rgb(0, 0, 0);
  text-align: center;
  margin-bottom: 78px;
}

@media screen and (max-width: 900px) {
  .photos 
  {
    margin: 10px 10px;
  }
}

@media screen and (max-width: 600px) {
  .column 
  {
    flex: 100% 1;
    max-width: 85%;
  }
}

@media only screen and (min-height: 1000px)
{
  .photos 
  {
    margin: 10px 10px;
  }
}
.footer-section
{
  margin-top: 200px;
  display: flex;
  flex-direction: column;
}

.email
{
  margin-top: 20px;
  text-align: center;
}

.email a
{
  color: rgb(0, 0, 0);
  font-family: "Chicago";
  font-size: 13px;
  text-shadow: 1px 1px rgb(175, 175, 175);
  font-style: italic;
  -webkit-transition: 0.2s all ease;
  transition: 0.2s all ease;
}

.email a:hover
{
  background: rgb(212, 212, 212);
}

.footer p
{
  margin-top: 10px;
  color: rgb(0, 0, 0);
  text-align: center;
  font-family: "Chicago";
  font-size: 12px;
}
.rectangle-outer
{
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 50px;
  padding-bottom: 7px;
  padding-left: 7px;
  padding-right: 7px;
  min-width: 250px;
  min-height: 350px;
  width: 72vw;
  height: 980px;
  border-radius: 4px;
  border-color: black;
  border-style: solid;
  border-width: 3px;
  background: rgb(219, 219, 219);
}

.rectangle-top
{
  display: flex;
  width: 100%;
  flex-direction: row;
}

.bar-container
{
  flex-direction: column;
  display: flex;
  justify-content:center;
  width: 100%;
  align-items: left;
}

.bar {
  width: 98%;
  height: 0.5px;
  background-color: rgb(0, 0, 0);
  margin: 3px 0;
  display: block;
}

.box-container-l
{
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 9px;
}

.box-container-r
{
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 0px;
}

.box
{
  width: 18px;
  height: 18px;
  background-color: rgb(200, 200, 200);
  border-color: rgb(112, 112, 112);
  border-style: inset;
  border-width: 2px;
  margin-left: 2px;
  display: block;
}

.rectangle-inner
{
  width: 99%;
  height: 960px;
  border-radius: 3px;
  border-color: rgb(0, 0, 0);
  border-style: double;
  border-width: 7px;
  background: rgb(255, 255, 255);
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
}

@media only screen and (max-height: 1200px)
{
  .rectangle-outer
  {
    height: 65vh;
    margin-top: 7vh;
  }
}

@media only screen and (max-width: 550px)
{
  .rectangle-outer
  {
    width: 90vw;
  }
}

@media only screen and (min-width: 1000px)
{
  .rectangle-outer
  {
    height: 70vh;
  }
}

@media only screen and (min-width: 1700px)
{
  .rectangle-outer
  {
    width: 50vw;
  }
}
