.projects-header h1
{
    color: rgb(0, 0, 0);
    font-family: "Chicago";
    font-weight: bold;
    text-shadow: 2px 2px rgb(180, 180, 180);
    font-style: oblique;
    font-size: 30px;
    margin-left: 10px;
}

.projects-section
{
    margin-bottom: 60px;
}

.projects-section ul
{
    padding: 0;
}

.project
{
    margin-bottom: 60px;
    margin-top: 90px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.project-photo img
{
    max-height: 165px;
    max-width:  165px;
}

.project-photo2 img
{
    height: 165px;
    width:  165px;
}

.projects-list h2
{
    font-family: "Chicago";
    font-size: 28px;
    color: rgb(21, 77, 231);
    text-shadow: 2px 2px rgb(175, 175, 175);
    text-align: center;
    margin: 8px;
}

.projects-list h3
{
    color: rgb(98, 98, 98);
    font-size: 17px;
    text-align: center;
    font-style: oblique;
    font-family: "Chicago";
}

.skills
{
    margin-top: 0px;
    display: flex;
    font-family: "Chicago";
    flex-direction: row;
    justify-content: center;
}

.skills li
{
    color: rgb(98, 98, 98);
    font-size: 14px;
    text-decoration: none;
    border: 1px solid rgb(98, 98, 98);
    border-radius: 3px;
    padding: 3px 10px;
    padding-bottom: 3px;
    margin: 3px;
    display: flex;
}

.project-description
{
    margin-top: 23px;
    color: rgb(0, 0, 0);
    width: 50%;
    font-family: "Chicago";
    align-items: center;
    justify-content: center;
}

.project-description p
{
    font-size: 18px;
    text-align: left;
    line-height: 1.3em;
    margin-bottom: 6px;
}

.github-link
{
    margin-top: 2px;
}

.github-link a
{
    color: rgb(71, 71, 71);
    font-family: "Chicago";
    text-decoration: underline;
    font-style: oblique;
    font-size: 20px;
    transition: 0.2s all ease;
}

.github-link a:hover
{
    background: rgb(212, 212, 212);
}

.demo-link
{
    margin-top: 2px;
}

.demo-link a
{
    color: rgb(71, 71, 71);
    font-family: "Chicago";
    text-decoration: underline;
    font-style: oblique;
    font-size: 20px;
    transition: 0.2s all ease;
}

.demo-link a:hover
{
    background: rgb(212, 212, 212);
}

@media only screen and (max-width: 700px)
{
    .project-description
    {
        width: 80%;
    }

    .project-description p
    {
        font-size: 4vw;
    }
}

@media only screen and (max-width: 500px)
{
    .skills
    {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .project
    {
        margin-top: 70px;
    }
}

@media only screen and (max-height: 1000px)
{
    .skills li
    {
        color: rgb(98, 98, 98);
        font-size: 12px;
        text-decoration: none;
        border: 1px solid rgb(98, 98, 98);
        border-radius: 3px;
        padding: 1px 6px;
        padding-bottom: 1px;
        margin: 2px;
    }

    .project-photo img
    {
        max-height: 130px;
        max-width:  130px;
    }

    .project-photo2 img
    {
        height: 130px;
        width:  130px;
    }

    .project
    {
        margin-bottom: 60px;
        margin-top: 50px;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
}