.topbar-container
{
  flex-direction: column;
  display: flex;
  margin-top: 0px;
  width: 100%;
  height: 29px;
  border-radius: 5px;
  border-color: rgb(96, 96, 96);
  border-style: outset;
  border-width: 2px;
  background: rgb(219, 219, 219);
}

.topbar-menu-container
{
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 0;
  list-style-type: none;
}

.topbar-menu-container li a
{
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-family: "Chicago";
  font-size: 19px;
  margin: 13px;
  overflow: hidden;
  transition: 0.5s all ease;
}

.topbar-menu-container li a:hover
{
  color: rgb(40, 40, 40);
}

.topbar-menu
{
  list-style-type: none;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 500px)
{
  .topbar-menu-container li a
  {
    font-size: 17px;
    margin: 9px;
  }
}