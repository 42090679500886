body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 18px;
}

::-webkit-scrollbar-track {
  background: rgb(170, 170, 170);
  display: block;
  border-color: rgb(0, 0, 0);
  border-width: 1px;
  border-style: inset;
}

::-webkit-scrollbar-thumb {
  background: rgb(219, 219, 219);
  display: block;
  border-color: rgb(0, 0, 0);
  border-width: 1px;
  border-style: outset;
}

/* SCROLL BUTTONS */
/* ::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
    display: block;
    border-color: rgb(0, 0, 0);
    border-width: 2px;
    border-style: outset;
    height: 17px;
    background-color: rgb(219, 219, 219);
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 900px) {
  body {
    display: block;
  }
}
