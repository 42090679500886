.photos-header h1
{
  color: rgb(0, 0, 0);
  padding-top: 10px;
  margin-left: 10px;
  text-shadow: 2px 2px rgb(180, 180, 180);
  font-style: oblique;
  font-family: "Chicago";
  font-weight: bold;
  font-size: 30px;
}

.photos img
{
  max-width: 100%;
  height: auto;
}

.photos
{
  display: flex;
  flex-wrap: wrap;
  margin: 0px 150px;
}

.column 
{
  flex: 25%;
  max-width: 30%;
  margin-left: auto;
  margin-right: auto;
}

.photo-box
{
  position: relative;
  padding-top: 0;
  margin-top: 10px;
}

.photo-box:hover img
{
  opacity: 0.3;
  transition: 0.5s all ease;
}

.photo-box:hover .detail
{
  opacity: 0;
}

.column img {
  vertical-align: middle;
}

.photos-link
{
  margin-top: 78px;
  font-family: "Chicago";
  font-size: 14px;
  color: rgb(0, 0, 0);
  text-align: center;
  margin-bottom: 78px;
}

@media screen and (max-width: 900px) {
  .photos 
  {
    margin: 10px 10px;
  }
}

@media screen and (max-width: 600px) {
  .column 
  {
    flex: 100%;
    max-width: 85%;
  }
}

@media only screen and (min-height: 1000px)
{
  .photos 
  {
    margin: 10px 10px;
  }
}