.main-container
{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

/*GENERAL BACKGROUND*/
body
{
  background: rgba(70, 104, 196, 0.752);
  overflow-y:hidden;
}

/* FONTS */
@font-face
{
  font-family: "Avenir Book";
  src: url(./fonts/AvenirLTStd-Book.otf);
}

@font-face
{
  font-family: "Avenir Roman";
  src: url(./fonts/AvenirLTStd-Roman.otf);
}

@font-face
{
  font-family: "Avenir Medium";
  src: url(./fonts/AvenirLTStd-Medium.otf);
}

@font-face
{
  font-family: "8 bit";
  src: url(./fonts/8-bit-pusab.ttf);
}

@font-face
{
  font-family: "Chicago";
  src: url(./fonts/ChicagoFLF.ttf);
}