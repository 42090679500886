.main-nav
{
  display: flex;
  flex-direction: column;
}

.main-nav ul
{
  padding: 0;
  margin-top: 0px;
}

.nav-menu 
{
  list-style-type: none;
  display: flex;
  width: min-content;
  flex-direction: column;
  justify-content: center;
}

.home-nav-menu .nav-item
{
  color: rgb(0, 0, 0);
  font-family: "Chicago";
  text-shadow: 1px 1px rgb(193, 193, 193);
  text-decoration: underline;
  font-style: italic;
  font-size: 22px;
  margin-top: 40px;
  margin-left: 8px;
  cursor: pointer;
  overflow: hidden;
  transition: 0.2s all ease;
}

.home-nav-menu .nav-item:hover
{
  background-color: rgb(212, 212, 212);
}

@media only screen and (max-width: 600px)
{
  .home-nav-menu .nav-item
  {
    font-size: 18px;
    margin-top: 30px;
    text-shadow: 1px 1px rgb(193, 193, 193);
  }
}