.bottom-container
{
  flex-direction: column;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: fit-content;
  height: fit-content;
  background: rgb(210, 210, 210);
}

.bottom-menu-container
{
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}

.bottom-menu-container ul
{
  padding: 0;
  list-style-type: none;
}​

.bottom-menu
{
  border-style: outset;
}

.bottom-menu-item i
{
  color: rgb(0, 0, 0);
  padding: 8px 8px;
  background: rgb(219, 219, 219);
  border-color: rgb(139, 139, 139);
  border-width: 4px;
  border-style: outset;
  transition: 0.2s all ease;
}

.bottom-menu-item i:hover
{
  background-color: rgb(152, 152, 152);
}

@media only screen and (max-height: 1000px)
{
  .bottom-menu-item i
  {
    color: rgb(0, 0, 0);
    padding: 4px 4px;
    background: rgb(219, 219, 219);
    border-color: rgb(169, 169, 169);
    border-width: 4px;
    border-style: outset;
    transition: 0.2s all ease;
  }
}