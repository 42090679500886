.profile-page
{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-position: center;
}

.rectangle
{
  background: linear-gradient(rgba(0,0,0,0.95), rgba(0,0,0,0.95)), no-repeat center;
}

.profile-name h1
{
  margin-top: 40px;
  margin-left: 5px;
  font-size: 47px;
  font-style: oblique;
  color: rgb(0, 0, 0);
  text-shadow: 3px 3px rgb(198, 198, 198);
  font-weight: bold;
  font-family: "Chicago";
}

.profile-description h1
{
  margin-top: 20px;
  margin-bottom: 0px;
  width: 100%;
  font-family: "Chicago";
  color:rgb(0, 0, 0);
  font-size: 22px;
  text-overflow: ellipsis;
}

#cursor {
  border-left: .8em solid rgba(110, 110, 110, 0.676);
  padding-top: 7px;
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@media only screen and (max-width: 600px)
{
  .profile-name h1
  {
    font-size: 27px;
    text-shadow: 2px 2px rgb(164, 164, 164);
  }

  .profile-description h1
  {
    font-size: 15px;
  }
}

