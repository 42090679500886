.rectangle-outer
{
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 50px;
  padding-bottom: 7px;
  padding-left: 7px;
  padding-right: 7px;
  min-width: 250px;
  min-height: 350px;
  width: 72vw;
  height: 980px;
  border-radius: 4px;
  border-color: black;
  border-style: solid;
  border-width: 3px;
  background: rgb(219, 219, 219);
}

.rectangle-top
{
  display: flex;
  width: 100%;
  flex-direction: row;
}

.bar-container
{
  flex-direction: column;
  display: flex;
  justify-content:center;
  width: 100%;
  align-items: left;
}

.bar {
  width: 98%;
  height: 0.5px;
  background-color: rgb(0, 0, 0);
  margin: 3px 0;
  display: block;
}

.box-container-l
{
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 9px;
}

.box-container-r
{
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 0px;
}

.box
{
  width: 18px;
  height: 18px;
  background-color: rgb(200, 200, 200);
  border-color: rgb(112, 112, 112);
  border-style: inset;
  border-width: 2px;
  margin-left: 2px;
  display: block;
}

.rectangle-inner
{
  width: 99%;
  height: 960px;
  border-radius: 3px;
  border-color: rgb(0, 0, 0);
  border-style: double;
  border-width: 7px;
  background: rgb(255, 255, 255);
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
}

@media only screen and (max-height: 1200px)
{
  .rectangle-outer
  {
    height: 65vh;
    margin-top: 7vh;
  }
}

@media only screen and (max-width: 550px)
{
  .rectangle-outer
  {
    width: 90vw;
  }
}

@media only screen and (min-width: 1000px)
{
  .rectangle-outer
  {
    height: 70vh;
  }
}

@media only screen and (min-width: 1700px)
{
  .rectangle-outer
  {
    width: 50vw;
  }
}